// Fund versioning - those that have the version fill added to their 'fundType'
// will have the new forms version adjustments created at the time of the version
module.exports = {
  FUND_VERSION: {
    v1: 'JUN-2023', //GUN-1396
    v2: 'SEP-2023', //GUN-1441
    v3: 'MAY-2024', //GUN-1492 | GUN-1491
    v4: 'JAN-2025',
  }
};
