/* eslint-disable react/sort-comp, array-callback-return, no-loop-func */
/* @flow */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import type { Connector } from 'react-redux';
import Helmet from 'react-helmet';
// import { notify } from 'react-notify-toast';
import * as action from './action';
import * as validations from '../../utils/form-validation';
import * as notify from '../../utils/notify';
import { FUND_TYPES } from '../../utils/constants/types';
import './styles.scss';
import {
  Context as ContextType,
  Administrator as AdministratorType,
  Reducer,
  Dispatch,
} from '../../types/index';
import * as utility from '../../utils/utility';
import TextInput from '../../components/TextInput/index';
import Button from '../../components/Button/index';
import TextArea from '../../components/TextArea/index';
import Banner from '../../components/Banner/index';
import FilterBox from '../../components/FilterBox/index';
import Alert from '../../components/Alert/index';
import Tooltip from '../../components/Tooltip/index';

import RadioButton from '../../components/Radio/index';
import Dropdown from '../../components/Dropdown';
import { FUND_VERSION } from 'utils/constants/fund-versions';

type Props = {
  router: Object,
  history: Object,
  context: ContextType,
  admin: AdministratorType,
  saveFund: () => {},
  editFund: () => {},
  getFundById: () => {},
  fetchFirms: () => {},
  setUpdatingStatus: () => {},
};

const initialState = {
  firm: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    displayName: 'Firm',
    validators: ['required'],
  },
  includesWireInstructions: {
    value: false,
    valid: true,
    errorMessage: '',
    displayName: 'Include Wiring Instructions',
    validators: [],
  },
  includesRegulatoryFund: {
    value: false,
    valid: true,
    errorMessage: '',
    displayName: 'Include Regulatory Fund Transfer paragraph?',
    validators: [''],
  },
  includesFormPFQuestion: {
    value: false,
    valid: true,
    errorMessage: '',
    displayName: 'Include Form PF question',
    validators: [''],
  },
  requireCapitalCommitment: {
    value: 'yes',
    valid: true,
    errorMessage: '',
    displayName: 'Require Capital Commitment',
    validators: [],
  },
  includeFundGpAccess: {
    value: 'no',
    valid: true,
    errorMessage: '',
    displayName: 'Include Fund in GP Access',
    validators: [],
  },
  currency: {
    value: 'usd',
    valid: true,
    errorMessage: '',
    displayName: 'Currency',
    validators: [],
  },
  fundType: {
    valid: true,
    errorMessage: '',
    validators: ['required'],
    displayName: 'Fund Type',
    value: '',
    footerText: '',
    footerTextFund2: '',
    footerTextFund3: '',
  },
  footerText: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Signature Page Footer',
    validators: ['required'],
  },
  footerTextFund2: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Fund 2 Signature Page Footer',
    validators: [''],
  },
  footerTextFund3: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Fund 3 Signature Page Footer',
    validators: [''],
  },
  name: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    displayName: 'Name',
    validators: ['required'],
  },
  nameFund2: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    displayName: 'Name Fund 2',
    validators: [''],
  },
  nameFund3: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Name Fund 3',
    validators: [''],
  },
  generalPartner: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    displayName: 'General partner',
    validators: ['required'],
  },
  clientNumber: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    displayName: 'Client number',
    validators: ['required', 'number'],
  },
  matterNumber: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    displayName: 'Matter number',
    validators: ['required', 'number'],
  },
  generalPartnerEmail: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    displayName: 'Contact emails',
    validators: ['required'],
  },
  taxFormsEmail: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Tax Forms Email',
    validators: [''],
  },
  coverLetter: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Cover letter',
    validators: [''],
  },
  gundersonEmails: {
    value: '',
    valid: true,
    isRequired: false,
    errorMessage: '',
    displayName: 'Gunderson Email Addresses',
    validators: ['required', 'multi-email'],
  },
  formValid: true,
  formType: 'create',
  fundId: '',
  ownerFirm: {
    name: '',
    nameFund2: '',
    nameFund3: '',
    address: '',
    emailPrimaryContact: '',
    phonePrimaryContact: '',
  },
  updating: false,
  managingMember: {
    value: '',
    valid: true,
    isRequired: true,
    errorMessage: '',
    displayName: 'Managing Member',
    validators: ['required'],
  },
};

// Export this for unit testing more easily
export class FundForm extends PureComponent {
  props: Props;
  state = initialState;
  static defaultProps: {
    router: {},
    history: {},
    admin: {},
    context: {},
    saveFund: () => {},
    editFund: () => {},
    getFundById: () => {},
    fetchFirms: () => {},
    setUpdatingStatus: () => {},
  };

  componentDidMount() {
    const { router, fetchFirms, getFundById } = this.props;
    fetchFirms();
    const routLocation = router.location.pathname;
    if (routLocation.indexOf('editFund') > -1) {
      const fundId = routLocation.split('editFund/')[1];
      // this could be obtained from in memory firms but assures model is fresh
      if (fundId && fundId !== '') {
        getFundById(fundId, this.editFundFieldMapping);
      }
    } else {
      this.verifyPreloadedFirm();
    }

    if (document.title !== 'Fund Formation Portal - New Fund') {
      notify.hideNotification();
    }
  }

  verifyPreloadedFirm = () => {
    const firmParam = utility.getUrlParameter('firm');
    if (firmParam) {
      this.setState({
        ...this.state,
        formType: 'create',
        firm: {
          ...this.state.firm,
          value: firmParam,
        },
      });
    }
  };

  setFormType = (type) => {
    this.setState({
      ...this.state,
      formType: type,
    });
  };

  editFundFieldMapping = (fetchedFund) => {
    if (fetchedFund._id) {
      const isTypeMultiFund = fetchedFund.fundType && fetchedFund.fundType.value === FUND_TYPES.LIMITED_PARTNER_MULTI_FUND.id || 
      fetchedFund.fundType.value === FUND_TYPES.LIMITED_PARTNERSHIP_MULTI_FUND.id ||
      fetchedFund.fundType.value === FUND_TYPES.ACCEL_ENTREPRENEURS_RETURNING_MULTI_FUND.id;
      this.setState({
        ...this.state,
        formType: 'edit',
        fundType: {
          ...this.state.fundType,
          value: fetchedFund.fundType ? fetchedFund.fundType.identifier : '',
          footerText: fetchedFund.fundType
            ? fetchedFund.fundType.footerText
            : '',
          footerTextFund2: fetchedFund.fundType
          ? fetchedFund.fundType.footerTextFund2  
          : '',
          footerTextFund3: fetchedFund.fundType
          ? fetchedFund.fundType.footerTextFund3 
          : '',
        },
        version: fetchedFund.version || this.state.version || '',
        ownerFirm: fetchedFund.firm,
        firm: {
          ...this.state.firm,
          value: fetchedFund.firm,
        },
        fundId: fetchedFund._id,
        name: {
          ...this.state.name,
          value: fetchedFund.fundLegalName,
        },
        nameFund2: {
          ...this.state.nameFund2,
          value: fetchedFund.fund2LegalName,
        },
        nameFund3: {
          ...this.state.nameFund3,
          value: fetchedFund.fund3LegalName,
        },
        generalPartner: {
          ...this.state.generalPartner,
          value: isTypeMultiFund ? 'N/A' : fetchedFund.generalPartnerLegalName,
        },
        managingMember: {
          ...this.state.managingMember,
          value: fetchedFund.managingMember,
        },
        clientNumber: {
          ...this.state.clientNumber,
          value: fetchedFund.clientNumber,
        },
        matterNumber: {
          ...this.state.matterNumber,
          value: fetchedFund.matterNumber,
        },
        generalPartnerEmail: {
          ...this.state.generalPartnerEmail,
          value: fetchedFund.generalPartnerEmailAddress,
        },
        taxFormsEmail: {
          ...this.state.taxFormsEmail,
          value: fetchedFund.taxFormsEmail || '',
        },
        coverLetter: {
          ...this.state.coverLetter,
          value: fetchedFund.coverLetter || '',
        },
        gundersonEmails: {
          ...this.state.gundersonEmails,
          value: fetchedFund.gundersonEmails,
        },
        includesWireInstructions: {
          ...this.state.includesWireInstructions,
          value: fetchedFund.includesWireInstructions,
        },
        includesRegulatoryFund: {
          ...this.state.includesRegulatoryFund,
          value: fetchedFund.includesRegulatoryFund,
        },
        includesFormPFQuestion: {
          ...this.state.includesFormPFQuestion,
          value: fetchedFund.includesFormPFQuestion,
        },
        requireCapitalCommitment: { 
          ...this.state.requireCapitalCommitment,
          value: fetchedFund.requireCapitalCommitment ? fetchedFund.requireCapitalCommitment : 'yes',
        },
        includeFundGpAccess: { 
          ...this.state.includeFundGpAccess,
          value: fetchedFund.includeFundGpAccess ? fetchedFund.includeFundGpAccess : 'no',
        },
        currency: {
          ...this.state.currency,
          value: fetchedFund.currency ? fetchedFund.currency : 'usd',
        },
        footerText: {
          ...this.state.footerText,
          value: fetchedFund.footerText || '',
        },
        footerTextFund2: {
          ...this.state.footerTextFund2,
          value: fetchedFund.footerTextFund2 || '',
        },
        footerTextFund3: {
          ...this.state.footerTextFund3,
          value: fetchedFund.footerTextFund3 || '',
        },
      });
    }
  };

  getFirmInformation = () => ({
    displayName: this.state.ownerFirm.name,
    displayNameFund2: this.state.ownerFirm.nameFund2,
    displayNameFund3: this.state.ownerFirm.nameFund3,
    description: this.state.ownerFirm.address,
    contactInfo: [
      this.state.ownerFirm.emailPrimaryContact,
      this.state.ownerFirm.phonePrimaryContact,
    ],
  });

  handleFirmChange = (event) => {
    event.preventDefault();
    this.setState(
      {
        firm: { ...this.state.firm, value: event.target.value },
      },
      () => {
        this.runFieldValidations('firm', this.state.firm.value);
      }
    );
  };
  handleWireInstructionsChange = (event) => {
    event.preventDefault();
    this.setState(
      {
        includesWireInstructions: {
          ...this.state.includesWireInstructions,
          value: event.target.value === 'yes' ? true : false,
        },
      },
      () => {
        this.runFieldValidations(
          'includesWireInstructions',
          this.state.includesWireInstructions.value
        );
      }
    );
  };

  handleRegulatoryFundChange = (event) => {
    event.preventDefault();
    this.setState(
      {
       includesRegulatoryFund: {
          ...this.state.includesRegulatoryFund,
          value: event.target.value === 'yes' ? true : false,
        },
      },
      () => {
        this.runFieldValidations(
          'includesRegulatoryFund',
          this.state.includesRegulatoryFund.value
        );
      }
    );
  };

  handleFormPFQuestionChange = (event) => {
    event.preventDefault();
    this.setState(
      {
        includesFormPFQuestion: {
          ...this.state.includesFormPFQuestion,
          value: event.target.value === 'yes' ? true : false,
        },
      },
      () => {
        this.runFieldValidations(
          'includesFormPFQuestion',
          this.state.includesFormPFQuestion.value
        );
      }
    );
  };
  
  handleRequireCapitalCommitment = (event) => {
    event.preventDefault();
    this.setState(
      {
        requireCapitalCommitment: {
          ...this.state.requireCapitalCommitment,
          value: event.target.value === 'yes' ? 'yes' : 'no',
        },
      },
      () => {
        this.runFieldValidations(
          'requireCapitalCommitment',
          this.state.requireCapitalCommitment.value
        );
      }
    );
  };

  handleIncludeFundGpAccess = (event) => {
    event.preventDefault();
    this.setState(
      {
        includeFundGpAccess: {
          ...this.state.includeFundGpAccess,
          value: event.target.value === 'yes' ? 'yes' : 'no',
        },
      },
      () => {
        this.runFieldValidations(
          'includeFundGpAccess',
          this.state.includeFundGpAccess.value
        );
      }
    );
  };

    handleCurrency = (event) => {
    event.preventDefault();
    this.setState(
      {
        currency: {
          ...this.state.currency,
          value: event.target.value === 'eur' ? 'eur' : 'usd',
        },
      },
      () => {
        this.runFieldValidations(
          'currency',
          this.state.currency.value
        );
      }
    );
  };

  handleNameChange = (event) => {
    event.preventDefault();
    this.setState(
      {
        name: {
          ...this.state.name,
          value: event.target.value,
        },
      },
      () => {
        this.runFieldValidations('name', this.state.name.value);
      }
    );
  };
  handleNameChangeFund2 = (event) => {
    event.preventDefault();
    this.setState(
      {
        nameFund2: {
          ...this.state.name,
          value: event.target.value,
        },
      },
      () => {
        this.runFieldValidations('nameFund2', this.state.nameFund2.value);
      }
    );
  };
  handleNameChangeFund3 = (event) => {
    event.preventDefault();
    this.setState(
      {
        nameFund3: {
          ...this.state.name,
          value: event.target.value,
        },
      },
      () => {
        this.runFieldValidations('nameFund3', this.state.nameFund3.value);
      }
    );
  };

  handleGeneralPartnerChange = (event) => {
    event.preventDefault();
    const isMultiFund = this.state.fundType.value === FUND_TYPES.LIMITED_PARTNER_MULTI_FUND.id || 
    this.state.fundType.value === FUND_TYPES.LIMITED_PARTNERSHIP_MULTI_FUND.id ||
    this.state.fundType.value === FUND_TYPES.ACCEL_ENTREPRENEURS_RETURNING_MULTI_FUND.id;
    this.setState(
      {
        generalPartner: {
          ...this.state.generalPartner,
          value: isMultiFund ? 'N/A' : event.target.value,
          isRequired: !isMultiFund,
          validators: [`${isMultiFund ? '' : 'required' }`]
        },
      },
      () => {
        this.runFieldValidations(
          'generalPartner',
          this.state.generalPartner.value
        );
      }
    );
  };

  handleManagingMemberChange = (event) => {
    event.preventDefault();
    this.setState({
      managingMember: {
        ...this.state.managingMember,
        value: event.target.value,
        isRequired:
          this.state.fundType.value === FUND_TYPES.LIMITED_LIABILITY_COMPANY.id,
      },
    });
  };

  handleClientNumberChange = (event) => {
    event.preventDefault();
    this.setState(
      {
        clientNumber: {
          ...this.state.clientNumber,
          value: event.target.value,
        },
      },
      () => {
        this.runFieldValidations('clientNumber', this.state.clientNumber.value);
      }
    );
  };

  handleMatterNumberChange = (event) => {
    event.preventDefault();
    this.setState(
      {
        matterNumber: {
          ...this.state.matterNumber,
          value: event.target.value,
        },
      },
      () => {
        this.runFieldValidations('matterNumber', this.state.matterNumber.value);
      }
    );
  };

  handleLink = (event) => {
    event.preventDefault();
    const { history } = this.props;
    history.push('/admin/main/dashboard');
  };

  handleEmailChange = (event) => {
    event.preventDefault();
    this.setState(
      {
        generalPartnerEmail: {
          ...this.state.generalPartnerEmail,
          value: event.target.value,
        },
      },
      () => {
        this.runFieldValidations(
          'generalPartnerEmail',
          this.state.generalPartnerEmail.value
        );
      }
    );
  };

  handleFundTypeChange = (event) => {
    event.preventDefault();
    const selectedFundTypeInformation = Object.keys(FUND_TYPES)
      .map((_fk) => {
        if (FUND_TYPES[_fk].id === event.target.value) {
          return FUND_TYPES[_fk];
        }
      })
      .filter(Boolean);
    const targetFooterText = selectedFundTypeInformation[0].label;
    const targetFooterTextFund2 = selectedFundTypeInformation[0].label;
    const taxFormsEmail = ''
    const targetFooterTextFund3 =  '';
    const isMultiFund = selectedFundTypeInformation[0].id === FUND_TYPES.LIMITED_PARTNER_MULTI_FUND.id || 
      selectedFundTypeInformation[0].id === FUND_TYPES.LIMITED_PARTNERSHIP_MULTI_FUND.id || 
      selectedFundTypeInformation[0].id === FUND_TYPES.ACCEL_ENTREPRENEURS_RETURNING_MULTI_FUND.id;
    this.setState(
      {
        ...this.state,
        fundType: {
          ...this.state.fundType,
          value: event.target.value,
          footerText: targetFooterText,
          footerTextFund2: targetFooterTextFund2,
          taxFormsEmail: taxFormsEmail,
          footerTextFund3: targetFooterTextFund3,
        },
        generalPartner: {
          ...this.state.generalPartner,
          value: isMultiFund ? 'N/A' : '',
          validators:[`${isMultiFund ? '': 'required' }`]
        },
        footerText: {
          ...this.state.footerText,
          value: targetFooterText,
        },
        footerTextFund2: {
          ...this.state.footerTextFund2,
          value: targetFooterTextFund2,
          validators:[`${selectedFundTypeInformation[0].id === 'partnership_abbr_multi-fund'? 'required':''}`]
        },
        taxFormsEmail: {
          ...this.state.taxFormsEmail,
          value: taxFormsEmail,
          isRequired: selectedFundTypeInformation[0].id !== 'iolar',
          validators:[`${selectedFundTypeInformation[0].id === 'iolar' ? '':'required'}`]
        },
        nameFund2: {
          ...this.state.nameFund2,
          validators:[`${selectedFundTypeInformation[0].id === 'partnership_abbr_multi-fund'? 'required':''}`]
        },
        footerTextFund3: {
          ...this.state.footerTextFund3,
          value: targetFooterTextFund3,
        },
        nameFund3: {
          ...this.state.nameFund3,
          validators:['']
        },
      },
      () => {
        this.runFieldValidations('fundType', this.state.fundType.value);
      }
    );
  };

  handleTaxFormsEmailChange = (event) => {
    event.preventDefault();
    this.setState(
      {
        taxFormsEmail: {
          ...this.state.taxFormsEmail,
          value: event.target.value,
        },
      },
      () => {
        this.runFieldValidations(
          'taxFormsEmail',
          this.state.taxFormsEmail.value
        );
      }
    );
  };

  handleGundersonEmailsChange = (event) => {
    event.preventDefault();
    this.setState(
      {
        gundersonEmails: {
          ...this.state.gundersonEmails,
          value: event.target.value.trim(),
        },
      },
      () => {
        this.runFieldValidations(
          'gundersonEmails',
          this.state.gundersonEmails.value
        );
      }
    );
  };

  handleCoverLetterChange = (event) => {
    event.preventDefault();
    this.setState(
      {
        coverLetter: {
          ...this.state.coverLetter,
          value: event.target.value,
        },
      },
      () => {
        this.runFieldValidations('coverLetter', this.state.coverLetter.value);
      }
    );
  };

  handleFooterTextChange = (event) => {
    event.preventDefault();
    this.setState({
      footerText: {
        ...this.state.footerText,
        value: event.target.value,
      },
    });
  };
   handleFooterTextFund2Change = (event) => {
    event.preventDefault();
    this.setState({
      footerTextFund2: {
        ...this.state.footerTextFund2,
        value: event.target.value,
      },
    });
  };
  handleFooterTextFund3Change = (event) => {
    event.preventDefault();
    this.setState({
      footerTextFund3: {
        ...this.state.footerTextFund3,
        value: event.target.value,
      },
    });
  };

  handleBackButton = (event) => {
    const { history } = this.props;
    const { fundId, formType } = this.state;
    if (formType === 'create') {
      history.push('/admin/main/dashboard'); // fixme use redux router
    } else {
      history.push(`/admin/main/fundDetail/${fundId}`);
    }
  };

  handleFormSubmit = async (event) => {
    event.preventDefault();

    // run validations for all fields
    for (const key of Object.keys(this.state)) {
      if (this.state[key].validators && this.state[key].validators.length > 0) {
      this.runFieldValidations(key, this.state[key].value);
      }
    }
    
    const { saveFund, editFund, history, setUpdatingStatus } = this.props;
    const fundType = {
      value: this.state.fundType.value,
      identifier: this.state.fundType.value,
      footerText: this.state.footerText.value,
      footerTextFund2: this.state.footerTextFund2.value,
      footerTextFund3: this.state.footerTextFund3.value,
    };
    const isTypeMultiFund = fundType.value === FUND_TYPES.LIMITED_PARTNER_MULTI_FUND.id || 
    fundType.value === FUND_TYPES.LIMITED_PARTNERSHIP_MULTI_FUND.id ||
    fundType.value === FUND_TYPES.ACCEL_ENTREPRENEURS_RETURNING_MULTI_FUND.id;
    const payload = {
      fundLegalName: this.state.name.value,
      fund2LegalName: this.state.nameFund2.value,
      fund3LegalName: this.state.nameFund3.value,
      generalPartnerLegalName: fundType.value && isTypeMultiFund ? 'N/A' : this.state.generalPartner.value,
      clientNumber: this.state.clientNumber.value,
      matterNumber: this.state.matterNumber.value,
      generalPartnerEmailAddress: this.state.generalPartnerEmail.value,
      taxFormsEmail: this.state.taxFormsEmail.value,
      coverLetter: this.state.coverLetter.value,
      gundersonEmails: this.state.gundersonEmails.value,
      firm: this.state.firm.value,
      managingMember: this.state.managingMember.value,
      includesWireInstructions: this.state.includesWireInstructions.value,
      includesFormPFQuestion: this.state.includesFormPFQuestion.value,
      includesRegulatoryFund: this.state.includesRegulatoryFund.value,
      requireCapitalCommitment: this.state.requireCapitalCommitment.value,
      includeFundGpAccess: this.state.includeFundGpAccess.value,
      currency: this.state.currency.value,
      footerText: this.state.footerText.value,
      footerTextFund2: this.state.footerTextFund2.value,
      footerTextFund3: this.state.footerTextFund3.value,
      fundType,
      version: this.state.version ? this.state.version : '',
    };
    if (this.verifyFormValidity()) {
      setUpdatingStatus(true);
      if (this.state.formType === 'create') {
        await saveFund(
          payload,
          history,
          `/admin/main/firmDetail/${this.state.firm.value}`,
          this.toggleUpdating
        );
      } else {
        payload._id = this.state.fundId;
        await editFund(
          payload,
          history,
          `/admin/main/firmDetail/${this.state.firm.value}`,
          this.toggleUpdating
        );
      }
      this.handleBackButton();
      this.toggleUpdating();
    }
  };

  handleFieldBlur = (event) => {
    event.preventDefault()
    this.runFieldValidations(event.target.id, event.target.value);
  };

  renderTextInput = (
    id,
    type,
    value,
    displayName,
    isRequired,
    size,
    hasError,
    errorMessage,
    changeHandler,
    helpText,
    disabled
  ) => {
    const inputDataTemp = {
      id,
      displayName,
      isRequired,
      size,
      hasError,
      type,
      errorMessage,
      value,
      wrapperClass: 'col-content',
      showHelp: helpText && helpText !== '',
      helpText,
      noTitle: !displayName,
      disabled,
    };
    return (
      <TextInput
        inputData={inputDataTemp}
        onChangeHandler={changeHandler}
        onBlurHandler={this.handleFieldBlur}
      />
    );
  };

  renderTextArea = (
    id,
    displayName,
    value,
    title,
    hasError,
    errorMessage,
    isRequired,
    changeHandler,
    helpText
  ) => {
    const inputDataTemp = {
      id,
      displayName,
      hasError,
      isRequired,
      errorMessage,
      value,
      title,
      showHelp: helpText && helpText !== '',
      helpText,
    };
    return (
      <TextArea
        inputData={inputDataTemp}
        onChangeHandler={changeHandler}
        onBlurHandler={this.handleFieldBlur}
      />
    );
  };

  renderRadio = (
    id,
    displayName,
    isSelected,
    name,
    identifier,
    helpText,
    changeHandler
  ) => {
    const inputDataTemp = {
      id,
      displayName,
      isSelected,
      name,
      identifier,
      showHelp: helpText && helpText !== '',
      helpText,
    };
    return (
      <RadioButton inputData={inputDataTemp} onChangeHandler={changeHandler} />
    );
  };

 requiredCapitalCommitmentDropdown = () => {
  return (
  <Dropdown
      inputData={{
        id: 'requireCapitalCommitment',
        value: this.state.requireCapitalCommitment.value === 'yes'
          ? 'yes'
          : 'no',
        title: 'Require Capital Commitment',
        size: 'medium',
        options: [
          { id: 'yes', value: 'Yes' },
          { id: 'no', value: 'No' },
        ],
        wrapperExtraClass: '',
        hasError: !this.state.requireCapitalCommitment.valid,
        errorMessage:
          this.state.requireCapitalCommitment.errorMessage,
      }}
      onChangeHandler={this.handleRequireCapitalCommitment}
    />
  )
 }

 includeFundGpAccessDropdown = () => {
  return (
  <Dropdown
      inputData={{
        id: 'includeFundGpAccess',
        value: this.state.includeFundGpAccess.value === 'yes'
          ? 'yes'
          : 'no',
        title: 'Include Fund in GP Access',
        size: 'medium',
        options: [
          { id: 'yes', value: 'Yes' },
          { id: 'no', value: 'No' },
        ],
        wrapperExtraClass: '',
        hasError: !this.state.includeFundGpAccess.valid,
        errorMessage:
          this.state.includeFundGpAccess.errorMessage,
      }}
      onChangeHandler={this.handleIncludeFundGpAccess}
    />
  )
 }

  currencyDropdown = () => {
  return (
  <Dropdown
      inputData={{
        id: 'currency',
        value: this.state.currency.value === 'eur'
          ? 'eur'
          : 'usd',
        title: 'Currency',
        size: 'medium',
        options: [
          { id: 'usd', value: 'USD ($)' },
          { id: 'eur', value: 'Euros (€)' },
        ],
        wrapperExtraClass: '',
        hasError: !this.state.currency.valid,
        errorMessage:
          this.state.currency.errorMessage,
      }}
      onChangeHandler={this.handleCurrency}
    />
  )
 }

  processFirmsSelectOptions = () => {
    const { admin } = this.props;
    const options = [];
    for (let i = 0; i < admin.firms.length; i += 1) {
      const firm = admin.firms[i];
      options.push({ id: firm._id, value: firm.name });
    }
    return options.sort((a, b) => a.value.localeCompare(b.value));
  };

  verifyFormValidity = () => {
    for (let i = 0; i < Object.keys(this.state).length; i += 1) {
      const keyName = Object.keys(this.state)[i];
      /** formValid is not a field * */
      if (
        keyName !== 'formValid' &&
        keyName !== 'formType' &&
        keyName !== 'fundId' &&
        keyName !== 'ownerFirm' &&
        keyName !== 'updating' &&
        keyName !== 'managingMember' &&
        keyName !== 'version'
      ) {
        const validationPassed = this.runFieldValidations(
          keyName,
          this.state[keyName].value
        );
        if (!validationPassed) {
          return false;
        }
      }
    }
    return true;
  };

  runFieldValidations = (fieldName, value) => {
    let validationResult = {};
    for (let i = 0; i < this.state[fieldName].validators.length; i += 1) {
      const validator = this.state[fieldName].validators[i];
      switch (validator) {
        case 'required':
          validationResult = fieldName !== 'nameFund3' ? validations.required(value) : true;
          this.setState({
            [fieldName]: {
              ...this.state[fieldName],
              value,
              valid: validationResult,
              errorMessage:
                validationResult === false
                  ? `${this.state[fieldName].displayName} is required`
                  : '',
            },
            // eslint-disable-next-line consistent-return
          }); // once state is set return false if not valid
          if (!validationResult) {
            return false;
          }
          break;
        case 'multi-email':
          const emailList = value.split(/,|;/);
          let validationMessage = '';
          emailList.map((_e) => {
            validationResult = validations.email(_e);
            if (!validationResult) {
              validationMessage = `${validationMessage} ${_e}`;
            }
          });
          if (validationMessage.length) {
            validationResult = false;
            validationMessage = `${validationMessage} wrong email format`;
          }
          this.setState({
            [fieldName]: {
              ...this.state[fieldName],
              value,
              valid: validationResult,
              errorMessage: !validationResult ? validationMessage : '',
            },
            // eslint-disable-next-line consistent-return
          }); // once state is set return false if not valid
          if (!validationResult) {
            return false;
          }
          break;
        case 'email':
          validationResult = validations.email(value);
          this.setState({
            [fieldName]: {
              ...this.state[fieldName],
              value,
              valid: validationResult,
              errorMessage:
                validationResult === false ? 'Wrong email format' : '',
            },
            // eslint-disable-next-line consistent-return
          }); // once state is set return false if not valid
          if (!validationResult) {
            return false;
          }
          break;
        case 'number':
          validationResult = validations.number(value);
          this.setState({
            [fieldName]: {
              ...this.state[fieldName],
              value,
              valid: validationResult,
              errorMessage:
                validationResult === false
                  ? `${this.state[fieldName].displayName} must be a number`
                  : '',
            },
            // eslint-disable-next-line consistent-return
          }); // once state is set return false if not valid
          if (!validationResult) {
            return false;
          }
          break;
        default:
          break;
      }
    }
    return true;
  };
  toggleUpdating = () => {
    const { setUpdatingStatus } = this.props;
    setUpdatingStatus(false);
    // this.setState({ ...this.state, updating: !this.state.updating });
  };
  render() {
    const { context } = this.props;
    const fundTypeOptions = Object.keys(FUND_TYPES).map((_k) => ({
      id: FUND_TYPES[_k].id,
      value: FUND_TYPES[_k].displayName,
    })).filter((option) => option.id !== FUND_TYPES.NEW_LIMITED_PARTNER.id)
    .filter((option) => option.id !== FUND_TYPES.ELEPHANT_PARTNER.id);
    const isIolar = this.state.fundType.value === FUND_TYPES.IOLAR.id;
    const isMultiFund = this.state.fundType.value === FUND_TYPES.LIMITED_PARTNER_MULTI_FUND.id || 
        this.state.fundType.value === FUND_TYPES.LIMITED_PARTNERSHIP_MULTI_FUND.id || 
        this.state.fundType.value === FUND_TYPES.ACCEL_ENTREPRENEURS_RETURNING_MULTI_FUND.id;
    const firmName = this.getFirmInformation().displayName ? this.getFirmInformation().displayName : null;
    return (
      <div>
          <Banner
            displayName={firmName || 'Select'}
            bannerType={'action'}
            onChangeHandler={this.handleFirmChange}
            onBlurHandler={this.handleFieldBlur}
            firmInformation={this.getFirmInformation()}
            content={{
              selectId: 'firm',
              value: this.state.firm.value,
              firmName: firmName ? firmName : '',
              title: 'SELECT FIRM',
              selectOptions: this.processFirmsSelectOptions(),
              isValid: this.state.firm.valid,
              errorMessage: this.state.firm.errorMessage,
            }}
          />
        <div className=" -container container-pull fund-container">
          <Helmet title="New Fund" />
          {/* <Alert
            inputData={{
              type: context.notificationType,
              title: context.title,
              message: context.message,
            }}
          /> */}
          <div className="container">
            <FilterBox
              data={{
                boxTitle: 'Back to Dashboard',
                boxTitleIsLink: true,
                infoText:
                  this.state.formType === 'create' ? 'NEW FUND' : 'EDIT FUND',
                buttonId: 'back-btn-filter-dash',
                buttonType: 'button',
                filterType: 'info',
              }}
              linkHandler={this.handleBackButton}
              buttonHandler={this.handleBackButton}
            />
            <form onSubmit={this.handleFormSubmit}>
              <div className="content-bg adminform-content">
                <div className="row">
                  <div className="col-lg-9 col-md-10 col-sm-11 col-xs-12 center-block">
                    <div className="row">
                      <Dropdown
                        inputData={{
                          id: 'selectFundType',
                          value: this.state.fundType.value,
                          displayName: 'Select a fund type',
                          title: 'Fund Type',
                          size: 'medium',
                          options: fundTypeOptions,
                          wrapperExtraClass: '',
                          isRequired: true,
                          hasError: !this.state.fundType.valid,
                          errorMessage: this.state.fundType.errorMessage,
                        }}
                        onChangeHandler={this.handleFundTypeChange}
                      />

                      <div>
                        {
                          this.renderTextInput(
                          'footerText',
                          'text',
                          this.state.footerText.value,
                          `${isMultiFund  ? 'Fund 1 ' : ''} Signature Page Footer`,
                          true,
                          'medium',
                          !this.state.footerText.valid,
                          this.state.footerText.errorMessage,
                          this.handleFooterTextChange,
                          '',
                          this.state.fundType.value ===
                            FUND_TYPES.FORESITE.id ||
                            this.state.fundType.value ===
                              FUND_TYPES.INITIALIZED.id
                      )}
                      </div>
                    </div>
                    <div className="row">
                    
                      {
                       this.renderTextInput('name','text', this.state.name.value, 
                       `Legal Name of Fund ${isMultiFund ? '1' : ''}`, true,
                        'medium', !this.state.name.valid, this.state.name.errorMessage, this.handleNameChange)
                      }
                      {isMultiFund && 
                       this.renderTextInput(
                          'footerTextFund2',
                          'text',
                          this.state.footerTextFund2.value,
                          'Fund 2 Signature Page Footer',
                          true,
                          'medium',
                          !this.state.footerTextFund2.valid,
                          this.state.footerTextFund2.errorMessage,
                          this.handleFooterTextFund2Change,
                          '',
                          false
                        )}
                      {isMultiFund && 
                        this.renderTextInput('nameFund2','text', this.state.nameFund2.value, 'Legal Name of Fund 2', true,
                        'medium', !this.state.nameFund2.valid, this.state.nameFund2.errorMessage, this.handleNameChangeFund2)  
                      }
                      {isMultiFund && 
                       this.renderTextInput(
                          'footerTextFund3',
                          'text',
                          this.state.footerTextFund3.value,
                          'Fund 3 Signature Page Footer (Optional)',
                          false,
                          'medium',
                          !this.state.footerTextFund3.valid,
                          this.state.footerTextFund3.errorMessage,
                          this.handleFooterTextFund3Change,
                          '',
                          false
                        )}
                      {isMultiFund && 
                        this.renderTextInput('nameFund3','text', this.state.nameFund3.value, 'Legal Name of Fund 3 (Optional)', true,
                        'medium', !this.state.nameFund3.valid, this.state.nameFund3.errorMessage, this.handleNameChangeFund3)  
                      }
                      {this.state.fundType.value ===
                      FUND_TYPES.LIMITED_LIABILITY_COMPANY.id
                        ? this.renderTextInput(
                            'generalPartner',
                            'text',
                            this.state.managingMember.value,
                            'Legal Name of Managing Member',
                            true,
                            'medium',
                            !this.state.managingMember.valid,
                            this.state.managingMember.errorMessage,
                            this.handleManagingMemberChange
                          )
                        : this.renderTextInput(
                            'generalPartner',
                            'text',
                            isMultiFund ? 'N/A' : this.state.generalPartner.value,
                            'Legal Name of General Partner',
                            !isMultiFund,
                            'medium',
                            !this.state.generalPartner.valid,
                            this.state.generalPartner.errorMessage,
                            this.handleGeneralPartnerChange,
                            '',
                            isMultiFund,
                          )}
                    </div>
                    <div className="row">
                      {this.renderTextInput(
                        'clientNumber',
                        'text',
                        this.state.clientNumber.value,
                        'Client Number',
                        true,
                        'medium',
                        !this.state.clientNumber.valid,
                        this.state.clientNumber.errorMessage,
                        this.handleClientNumberChange,
                        '4- or 5-digit code, e.g. 13618'
                      )}
                      {this.renderTextInput(
                        'matterNumber',
                        'text',
                        this.state.matterNumber.value,
                        'Matter Number',
                        true,
                        'medium',
                        !this.state.matterNumber.valid,
                        this.state.matterNumber.errorMessage,
                        this.handleMatterNumberChange,
                        '3-digit code, e.g. 002'
                      )}
                    </div>
                    <div className="row">
                      {this.renderTextInput(
                        'gundersonEmails',
                        'text',
                        this.state.gundersonEmails.value,
                        'Gunderson Email Addresses',
                        true,
                        'medium',
                        !this.state.gundersonEmails.valid,
                        this.state.gundersonEmails.errorMessage,
                        this.handleGundersonEmailsChange,
                        'Enter email address(es), separated by commas, of all attorneys who should receive a PDF copy of signed questionnaires for this fund, AND/OR include the designated fund email address, e.g. KPCBXV@gunder.com.'
                      )}
                      {this.renderTextInput(
                        'generalPartnerEmail',
                        'text',
                        this.state.generalPartnerEmail.value,
                        'VC Contact Email Addresses',
                        true,
                        'medium',
                        !this.state.generalPartnerEmail.valid,
                        this.state.generalPartnerEmail.errorMessage,
                        this.handleEmailChange,
                        'Enter email address(es), separated by commas, of the VC contacts who should receive the VC Update, a listing of what has closed and what is pending for this fund.'
                      )}
                    </div>
                    <div className="row">
                      {!isIolar && this.renderTextInput(
                        'taxFormsEmail',
                        'text',
                        this.state.taxFormsEmail.value,
                        'Tax Forms Email Address',
                        true,
                        'medium',
                        !this.state.taxFormsEmail.valid,
                        this.state.taxFormsEmail.errorMessage,
                        this.handleTaxFormsEmailChange,
                        'Enter the email address where tax forms and supplemental materials should be sent for this fund.'
                      )}
                      <Dropdown
                        inputData={{
                          id: 'includesWireInstructions',
                          value: this.state.includesWireInstructions.value
                            ? 'yes'
                            : 'no',
                          title: 'Include Wiring Instructions',
                          size: 'medium',
                          options: [
                            { id: 'yes', value: 'Yes' },
                            { id: 'no', value: 'No' },
                          ],
                          wrapperExtraClass: '',
                          hasError: !this.state.includesWireInstructions.valid,
                          errorMessage:
                            this.state.includesWireInstructions.errorMessage,
                        }}
                        onChangeHandler={this.handleWireInstructionsChange}
                      />

                  {isIolar && this.requiredCapitalCommitmentDropdown()}
                    </div>
                    <div className="row">
                    {this.state.version && 
                    (this.state.version === FUND_VERSION.v1 || 
                     this.state.version === FUND_VERSION.v2 || 
                     this.state.version === FUND_VERSION.v3 ||
                     this.state.version === FUND_VERSION.v4) ||
                     this.state.formType === 'create'  ?
                      <>
                      {!isMultiFund && 
                      <Dropdown
                        inputData={{
                          id: 'includesRegulatoryFund',
                          value: this.state.includesRegulatoryFund.value
                            ? 'yes'
                            : 'no',
                          title: 'Include Regulatory Fund Transfer Paragraph?',
                          size: 'medium',
                          options: [
                            { id: 'yes', value: 'Yes' },
                            { id: 'no', value: 'No' },
                          ],
                          wrapperExtraClass: '',
                          hasError: !this.state.includesRegulatoryFund.valid,
                          errorMessage:
                            this.state.includesRegulatoryFund.errorMessage,
                        }}
                        onChangeHandler={this.handleRegulatoryFundChange}
                      />}
                       <Dropdown
                        inputData={{
                          id: 'includesFormPFQuestion',
                          value: this.state.includesFormPFQuestion.value
                            ? 'yes'
                            : 'no',
                          title: 'Include Form PF question?',
                          size: 'medium',
                          options: [
                            { id: 'yes', value: 'Yes' },
                            { id: 'no', value: 'No' },
                          ],
                          wrapperExtraClass: '',
                          hasError: !this.state.includesFormPFQuestion.valid,
                          errorMessage:
                            this.state.includesFormPFQuestion.errorMessage,
                        }}
                        onChangeHandler={this.handleFormPFQuestionChange}
                      />
                      </> : null}
                   {!isIolar && this.requiredCapitalCommitmentDropdown()}
                   {this.includeFundGpAccessDropdown()}
                   {this.currencyDropdown()}
                      {/* todo Input ids should be equal to state name to ease
                         validation logic */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="col-content">
                          <h5 className="color-black tooltip-positioning">
                            {' '}
                            Cover Letter{' '}
                          </h5>
                          <Tooltip
                            className="tooltip-positioning tooltip-margin"
                            data={{
                              message:
                                'Only include the body of your cover letter.',
                              extraClass: 'tooltip-margin',
                            }}
                          />
                          {this.renderTextArea(
                            'coverLetter',
                            'Add Cover Letter',
                            this.state.coverLetter.value,
                            'Cover Letter',
                            !this.state.coverLetter.valid,
                            this.state.coverLetter.errorMessage,
                            true,
                            this.handleCoverLetterChange
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row adminform-footer text-align-center">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <Button
                          inputData={{
                            id: 'new-fund-back',
                            displayName: 'Back',
                            isBack: true,
                            type: 'button',
                          }}
                          clickHandler={this.handleBackButton}
                        />
                        <Button
                          inputData={{
                            id: 'new-fund-submit',
                            displayName: 'Submit',
                            type: 'submit',
                          }}
                          clickHandler={this.handleFormSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state: Reducer) => ({
  admin: state.admin,
  router: state.router,
  context: state.context,
});
const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  (dispatch: Dispatch) => ({
    saveFund: (fundPayload, history, returnUrl, updatingCb) =>
      dispatch(action.saveFund(fundPayload, history, returnUrl, updatingCb)),
    editFund: (fundPayload, history, returnUrl, updatingCb) =>
      dispatch(action.editFund(fundPayload, history, returnUrl, updatingCb)),
    fetchFirms: () => dispatch(action.fetchFirms()),
    setUpdatingStatus: (isLoading) =>
      dispatch({ type: 'UPDATE_LOADER_STATUS', isLoading }),
    getFundById: (firmId, callback) =>
      dispatch(action.getFundById(firmId, callback)),
  })
);
export default connector(FundForm);
